<template>
  <div class="h-96 text-center">
    <h3 class="text-xl pt-40 pb-4">The requested page not found</h3>
    <h1 class="text-4xl text-red-900">404</h1>
  </div>
</template>

<script>
  export default {
    name: "NotFound",
  };
</script>

<style></style>
