<template>
  <h1 class="text-3xl text-cyan-600 font-bold underline my-10 text-center">
    Lets have some fun!!
  </h1>

  <div class="max-w-xl mx-auto">
    <router-link  to="/ninja">
    <div
      class="p-6 m-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <h5
        class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white"
      >
        Reaction Ninja
      </h5>

      <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">
        Touch the board as fast and you can and show your ninja-fast reactions.
      </p>
    </div>
  </router-link>
  <router-link to="/counter-to-20">
    <div
      class="p-6 m-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <h5
        class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white"
      >
        Count to 20
      </h5>

      <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">
        Coming Soon....
      </p>
    </div>
  </router-link>
  <router-link to="country-choose">
    <div
      class="p-6 m-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
    >
      <h5
        class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white"
      >
        Country Choose
      </h5>

      <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">
        Coming Soon....
      </p>
    </div>
  </router-link>
  </div>
</template>

<script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'

  export default {
    name: "HomeView",
    components: {},
  };
</script>
