<template>
  <div class="">
    <nav class="bg-white border-gray-200 dark:bg-gray-900 mb-3">
      <div
        class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4"
      >
      <router-link to="/">
        <div class="flex items-center">
          <img
            src="https://flowbite.com/docs/images/logo.svg"
            class="h-8 mr-3"
            alt="Flowbite Logo"
          />
          <span
            class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
            >XYZ Fun</span
          >
        </div>
      </router-link>
        
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
          @click="toggleNav"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div :class="{'w-full md:block md:w-auto':true,'hidden':isHidden}" id="navbar-default">
          <ul
            class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
          >
            <li>
              <routerLink
                to="/"
                class="font-bold block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                aria-current="page"
                >Home</routerLink
              >
            </li>
            <li>
              <routerLink
                to="/about"
                class="font-bold block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >About</routerLink
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="min-h-[90vh]">
      <router-view />
    </div>
    <footer class="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
      <div
        class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between"
      >
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400"
          >© 2023
          <a href="https://flowbite.com/" class="hover:underline">PM Tech</a>.
          All Rights Reserved.
        </span>
        <ul
          class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0"
        >
          <li>
            <router-link to="/about" class="mr-4 hover:underline md:mr-6"
              >About</router-link
            >
          </li>
          <li>
            <router-link to="/policy" class="mr-4 hover:underline md:mr-6"
              >Privacy Policy</router-link
            >
          </li>
          <li>
            <router-link to="licene" class="mr-4 hover:underline md:mr-6"
              >Licensing</router-link
            >
          </li>
          <li>
            <router-link to="contact" class="hover:underline"
              >Contact</router-link
            >
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<style>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .router-link-exact-active {
    color: blue !important;
  }
</style>

<script>
 export default {
   data(){
    return {
      isHidden: true
    };
   },
   methods: {
    toggleNav() {
      this.isHidden = !this.isHidden;
    }
   }
  };
</script>